<template>
  <pricing-rules-form
    ref="pricing-rules-form"
    :is-create-form="true"
  />
</template>
<script>
import PricingRulesForm from '../components/PricingRulesForm.vue'

export default {
  name: 'PricingRulesCreate',
  inject: ['pricingRulesModuleName'],
  components: {
    PricingRulesForm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['pricing-rules-form']) {
      this.$refs['pricing-rules-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  data() {
    return {
      currencyID: null,
    }
  },
  async created() {
    await this.$store.dispatch(`${this.MODULE_NAME}/getCurrenciesList`)
      .then(res => {
        const { data } = res.data.data

        if (data.length) {
          // eslint-disable-next-line prefer-destructuring
          this.currencyID = data[0]
        }
      })

    const initialData = {
      is_active: false,
      default_item_price_fill_order: false,
      service_charge_first_invoice: false,
      show_cost_deleted_lines: false,
      auto_extend_charge_duration: false,
      check_credit_limit_hold_orders: false,
      reparation_cost: false,
      consumable_discounted_amount: false,
      consumable_first_invoice: false,
      non_chargeable_sunday: false,
      non_chargeable_monday: false,
      non_chargeable_tuesday: false,
      non_chargeable_wednesday: false,
      non_chargeable_thursday: false,
      non_chargeable_friday: false,
      non_chargeable_saturday: false,
      currency_id: this.currencyID,
    }
    this.$store.commit(`${this.pricingRulesModuleName}/SET_FORM`, initialData)
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_PRICING_RULES_FORM_CLONE`, initialData)
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_NAME = 'pricing-rules'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
